import { css, styled } from '@mui/material';

import { PALETTE } from '../../../theme/palette';
import { MIN_WIDTH_MD } from '../../../theme/breakpoints';

import { Button } from '../../../components/ui';

export const StyledAdditionalDentoloInsuranceCard = styled('div')(
  ({ image }) => css`
    position: relative;
    padding: 1rem 1rem 0 1rem;
    background-color: ${PALETTE.ULTRA_CLEAR_LIGHT_BLUE};
    height: 220px;

    &::before {
      content: '';
      position: absolute;
      top: 4.5rem;
      right: 1.125rem;
      width: 114px;
      height: 116px;
      background: url(${image}) no-repeat;
      background-size: 115px auto;
    }

    @media (min-width: ${MIN_WIDTH_MD}) {
      padding-top: 2rem;
      height: 300px;
      font-size: 1.5rem;

      &::before {
        top: 50%;
        transform: translateY(-50%);
        right: 1.25rem;
        width: 250px;
        height: 243px;
        background-size: 250px auto;
      }
    }
  `
);

export const StyledAdditionalDentoloInsuranceCardContent = styled('div')`
  width: auto;
  height: 100%;

  @media (min-width: ${MIN_WIDTH_MD}) {
    width: 330px;
  }
`;

export const StyledAdditionalDentoloInsuranceCardTitle = styled('h1')`
  width: 315px;
  font-size: 1.125rem;
  line-height: 22px;

  > span {
    display: inline;
    padding-left: 2px;
    padding-right: 2px;
    background: linear-gradient(to bottom, transparent 62%, ${PALETTE.VITALIZED_GREEN} 38%);
    background-repeat: no-repeat;
  }

  @media (min-width: ${MIN_WIDTH_MD}) {
    width: 100%;
    font-size: 1.5rem;
    line-height: 30px;
  }
`;

export const StyledAdditionalDentoloInsuranceCardText = styled('div')`
  width: 200px;
  margin-top: 0.75rem;
  font-size: 0.75rem;
  line-height: 15px;

  @media (min-width: ${MIN_WIDTH_MD}) {
    margin-top: 1rem;
    width: 100%;
    font-size: 1.125rem;
    line-height: 22px;
  }
`;

export const StyledAdditionalDentoloInsuranceCardButton = styled(Button)`
  margin-top: 0.815rem;
  height: 3.5rem;

  @media (min-width: ${MIN_WIDTH_MD}) {
    margin-top: 1.5rem;
    height: 4rem;
    font-size: 1.125rem;
    line-height: 22px;
  }
`;
