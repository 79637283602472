import React from 'react';
import { useMutation } from '@apollo/client';

import { CREATE_DENTOLO_LEAD_WITH_CUSTOMER_DATA } from '../../../graphql/mutations/leads';
import { imgLinks, DENTOLO_ADDITIONAL_INSURANCE_URL } from '../../../shared';

import {
  StyledAdditionalDentoloInsuranceCard,
  StyledAdditionalDentoloInsuranceCardButton,
  StyledAdditionalDentoloInsuranceCardContent,
  StyledAdditionalDentoloInsuranceCardText,
  StyledAdditionalDentoloInsuranceCardTitle,
} from './AdditionalDentoloInsuranceCard.styled';

const goToFunnel = ({ uuid }) => {
  if (!uuid) return;

  const urlToInsureAnotherPetFunnel = `${DENTOLO_ADDITIONAL_INSURANCE_URL}&uuid=${uuid}`;

  window.open(urlToInsureAnotherPetFunnel, '_blank');
};

const handleRequestComplete = (data) => {
  const newUuid = data?.insureAnotherPerson?.uuid;

  if (newUuid) {
    goToFunnel({ uuid: newUuid });
  }
};

function AdditionalDentoloInsuranceCard() {
  const [
    createDentoloLeadWithCustomerData,
    { data: response, called: newUuidCalled, loading: newUuidLoading },
  ] = useMutation(CREATE_DENTOLO_LEAD_WITH_CUSTOMER_DATA);

  const additionalDentoloInsuranceBtnClick = () => {
    const newLeadUuid = response?.insureAnotherPerson?.uuid;

    if (newLeadUuid) {
      goToFunnel({ uuid: newLeadUuid });
      return;
    }

    createDentoloLeadWithCustomerData({
      onCompleted: handleRequestComplete,
    });
  };

  const disableButton = newUuidCalled && newUuidLoading;

  return (
    <StyledAdditionalDentoloInsuranceCard
      image={imgLinks['carousel.another.dentolo.contract.image']}
    >
      <StyledAdditionalDentoloInsuranceCardContent>
        <StyledAdditionalDentoloInsuranceCardTitle>
          Jetzt <span>weitere Familienmitglieder</span> absichern
        </StyledAdditionalDentoloInsuranceCardTitle>

        <StyledAdditionalDentoloInsuranceCardText>
          Sie können jederzeit Kinder in Ihren dentolo Unfallschutz aufnehmen oder neue Verträge für
          weitere Familienmitglieder abschließen.
        </StyledAdditionalDentoloInsuranceCardText>

        <StyledAdditionalDentoloInsuranceCardButton
          type="button"
          onClick={additionalDentoloInsuranceBtnClick}
          disabled={disableButton}
        >
          Zur Anmeldung
        </StyledAdditionalDentoloInsuranceCardButton>
      </StyledAdditionalDentoloInsuranceCardContent>
    </StyledAdditionalDentoloInsuranceCard>
  );
}

export default AdditionalDentoloInsuranceCard;
