// Add slides/cards for the `PromotionCarousel` in this file
import React from 'react';

import {
  DA_DIREKT_POLICY_CATEGORIES,
  DENTOLO_DENTAL_POLICY_CATEGORIES,
  PETOLO_PET_HEALTH_POLICY_CATEGORIES,
  PETOLO_PET_LIABILITY_POLICY_CATEGORIES,
  RISK_CARRIER_AMTRUST,
  SPECIAL_TARIFF_POLICY_CATEGORIES,
  VITOLO_HEALTH_POLICY_CATEGORIES,
} from '../../../shared';

import ReferralLinkCardDentolo from '../ReferralLinkCard/ReferralLinkCardDentolo';
import ReferralLinkCardPetolo from '../ReferralLinkCard/ReferralLinkCardPetolo';
import VitoloCrossSellingCard from '../VitoloCrossSellingCard/VitoloCrossSellingCard';
import VitoloBetterDoc from '../VitoloBetterDoc/VitoloBetterDoc';
import AdditionalDentoloInsuranceCard from '../AdditionalDentoloInsuranceCard/AdditionalDentoloInsuranceCard';
import FressnapfFriendsCard from '../FressnapfFriendsCard';

import ReferralLinkCardDaDirektDental from '../ReferralLinkCard/ReferralLinkCardDaDirektDental';
import ReferralLinkCardDaDirektPet from '../ReferralLinkCard/ReferralLinkCardDaDirektPet';
import DaDirektCrossSellingDentalCard from '../DaDirektCrossSelling/Dental/DaDirektDentalCard';
import DaDirektCrossSellingPetCard from '../DaDirektCrossSelling/Pet/DaDirektPetCard';
import DaDirektCrossSellingHealthCard from '../DaDirektCrossSelling/Health/DaDirektHealthCard';

/*
  - `hidden` property hides the card from policyCategories/riskCarriers in its value
  - Empty array will not render the carousel
*/

// DENTOLO
export const promotionSlidesDentolo = [
  {
    id: 'vitolo-cross-selling-dentolo',
    card: <VitoloCrossSellingCard />,
    hidden: [...DA_DIREKT_POLICY_CATEGORIES],
  },
  {
    id: 'link-referral-dentolo',
    card: <ReferralLinkCardDentolo />,
    hidden: [...DA_DIREKT_POLICY_CATEGORIES, RISK_CARRIER_AMTRUST],
  },
  {
    id: 'link-additional-dentolo-insurance',
    card: <AdditionalDentoloInsuranceCard />,
    hidden: [...DA_DIREKT_POLICY_CATEGORIES, RISK_CARRIER_AMTRUST],
  },
  {
    id: 'link-referral-da-direkt-dental',
    card: <ReferralLinkCardDaDirektDental />,
    hidden: [...DENTOLO_DENTAL_POLICY_CATEGORIES, RISK_CARRIER_AMTRUST],
  },
  {
    id: 'link-cross-selling-da-direkt-health',
    card: (
      <DaDirektCrossSellingHealthCard trackingparams="WT.mc_id=owned.intern.dentolo.kzv.kzv-allgemein.kzv-allgemein.kupo" />
    ),
    hidden: [...DENTOLO_DENTAL_POLICY_CATEGORIES, RISK_CARRIER_AMTRUST],
  },
  {
    id: 'link-cross-selling-da-direkt-pet',
    card: (
      <DaDirektCrossSellingPetCard trackingparams="WT.mc_id=owned.intern.dentolo.tkv.tkv-allgemein.tkv_allgemein.kupo" />
    ),
    hidden: [...DENTOLO_DENTAL_POLICY_CATEGORIES, RISK_CARRIER_AMTRUST],
  },
];

// PETOLO
export const promotionSlidesPetolo = [
  {
    id: 'vitolo-cross-selling-petolo',
    card: <VitoloCrossSellingCard />,
    hidden: [...DA_DIREKT_POLICY_CATEGORIES, ...SPECIAL_TARIFF_POLICY_CATEGORIES],
  },
  {
    id: 'link-referral-petolo',
    card: <ReferralLinkCardPetolo />,
    hidden: [
      ...DA_DIREKT_POLICY_CATEGORIES,
      ...PETOLO_PET_LIABILITY_POLICY_CATEGORIES,
      ...SPECIAL_TARIFF_POLICY_CATEGORIES,
      RISK_CARRIER_AMTRUST,
    ],
  },
  {
    id: 'link-referral-da-direkt-pet',
    card: <ReferralLinkCardDaDirektPet />,
    hidden: [
      ...PETOLO_PET_HEALTH_POLICY_CATEGORIES,
      ...PETOLO_PET_LIABILITY_POLICY_CATEGORIES,
      ...SPECIAL_TARIFF_POLICY_CATEGORIES,
    ],
  },
  {
    id: 'link-cross-selling-da-direkt-dental',
    card: (
      <DaDirektCrossSellingDentalCard trackingparams="WT.mc_id=owned.intern.petolo.zzv.zzv-allgemein.zzv_allgemein.kupo" />
    ),
    hidden: [
      ...PETOLO_PET_HEALTH_POLICY_CATEGORIES,
      ...PETOLO_PET_LIABILITY_POLICY_CATEGORIES,
      ...SPECIAL_TARIFF_POLICY_CATEGORIES,
    ],
  },
  {
    id: 'link-cross-selling-da-direkt-health',
    card: (
      <DaDirektCrossSellingHealthCard trackingparams="WT.mc_id=owned.intern.petolo.kzv.kzv-allgemein.kzv-allgemein.kupo" />
    ),
    hidden: [
      ...PETOLO_PET_HEALTH_POLICY_CATEGORIES,
      ...PETOLO_PET_LIABILITY_POLICY_CATEGORIES,
      ...SPECIAL_TARIFF_POLICY_CATEGORIES,
    ],
  },
  {
    id: 'link-fressnapf-friends',
    card: <FressnapfFriendsCard />,
    hidden: [
      ...PETOLO_PET_LIABILITY_POLICY_CATEGORIES,
      ...DA_DIREKT_POLICY_CATEGORIES,
      ...SPECIAL_TARIFF_POLICY_CATEGORIES,
    ],
  },
];

// Vitolo
export const promotionSlidesVitolo = [
  {
    id: 'vitolo-better-doc',
    card: <VitoloBetterDoc />,
  },
  {
    id: 'link-cross-selling-da-direkt-dental',
    card: (
      <DaDirektCrossSellingDentalCard trackingparams="WT.mc_id=owned.intern.vitolo.zzv.zzv-allgemein.zzv_allgemein.kupo" />
    ),
    hidden: [...VITOLO_HEALTH_POLICY_CATEGORIES],
  },
  {
    id: 'link-cross-selling-da-direkt-pet',
    card: (
      <DaDirektCrossSellingPetCard trackingparams="WT.mc_id=owned.intern.vitolo.tkv.tkv-allgemein.tkv_allgemein.kupo" />
    ),
    hidden: [...VITOLO_HEALTH_POLICY_CATEGORIES],
  },
];
